.drawer {
  display: block;
  position: fixed;

  top: 0px;
  left: 0px;

  transition: opacity 0.1s ease, transform 0.2s ease;
  opacity: 1;
  transform: translateY(0);
}

.drawer.hidden {
    opacity: 0;
    transform: translateY(20px);
}
